
import { Component, Mixins } from 'vue-property-decorator';
import { AnyObject, HeaderItem, ListingComponentProps } from '@/types';

import ActivitiesJobsConfsMixin, {
	NB_EXECUTION,
	NB_SUCCESS,
	NB_FAILED,
} from '@/mixins/data-operations/collection/activities-jobs-confs-mixin';
import RunCollectionMixin from '@/mixins/data-operations/collection/run-collection-mixin';
import ListingComponent from '@/components/data-operations/common/listing/ListingComponent.vue';

import { Statuses } from '@/constants/data-operations/filters';
import {
	CONFIGURATION_ID,
	CONFIGURATION_TYPE,
	DURATION,
	ENVIRONMENT,
	EXECUTION_DATE_UTC,
	STATUS,
} from '@/constants/data-operations/listing/header-items';
import { ConfigurationTypes } from '@/constants/data-operations/configuration-types';
import { ACTIVITIES_OVERVIEW_JOBS_LISTING, ACTIVITIES_OVERVIEW_RUNS_LISTING } from '@/constants/router/routes-names';

@Component({
	components: { ListingComponent },
})
export default class CollectionListing extends Mixins(ActivitiesJobsConfsMixin, RunCollectionMixin) {
	getSearchValue(configuration_id: string, configuration_type: ConfigurationTypes, job_id: string) {
		let search = '';

		if (this.isJobIdNull(job_id)) {
			search = configuration_id;
		} else {
			if ([ConfigurationTypes.StorageToTables, ConfigurationTypes.StorageToStorage].includes(configuration_type)) {
				const jobIdSplit = job_id.split('|');
				const stringToRemove = `|${jobIdSplit[jobIdSplit.length - 1]}`;
				search = job_id.replace(stringToRemove, '');
			} else {
				search = job_id;
			}
		}

		return search;
	}

	getExecutionsLink({ configuration_id, configuration_type, job_id }: AnyObject) {
		return {
			name: ACTIVITIES_OVERVIEW_RUNS_LISTING,
			query: { search: this.getSearchValue(configuration_id, configuration_type, job_id), configuration_type },
		};
	}

	getNumberOfJobIDLink({ configuration_id, configuration_type, job_id }: AnyObject) {
		return {
			name: ACTIVITIES_OVERVIEW_JOBS_LISTING,
			query: {
				search: this.getSearchValue(configuration_id, configuration_type, job_id),
			},
		};
	}

	get listingComponentProps(): ListingComponentProps {
		let headers: HeaderItem[] = [];
		let overriddenColumns: string[] = [];

		const NB_JOB_ID = { text: '# Distinct Jobs', sortable: true, value: 'nb_job_id' };

		headers = [
			ENVIRONMENT,
			CONFIGURATION_TYPE,
			CONFIGURATION_ID,
			NB_EXECUTION,
			NB_JOB_ID,
			{
				...STATUS,
				text: 'Last Status',
			},
			{
				...EXECUTION_DATE_UTC,
				text: 'Last Execution',
			},
			{
				...DURATION,
				text: 'Last Duration',
			},
			NB_SUCCESS,
			NB_FAILED,
		];
		overriddenColumns = [
			'configuration_id',
			'executions',
			'nb_job_id',
			'execution_date_utc',
			'nb_success',
			'nb_failed',
		];

		const customDataFetching = async () => {
			const activitiesRuns = await this.getTailerActivitiesRuns();
			return Object.values(
				activitiesRuns.reduce((accumulator, currentValue) => {
					const id = `${currentValue.environment}-${currentValue.configuration_type}-${currentValue.configuration_id}`;
					accumulator[id] = [...(accumulator[id] || []), currentValue];
					return accumulator;
				}, {})
			).map((activitiesRunsGroup) => {
				let nb_success = 0;
				let nb_failed = 0;
				const jobIds: string[] = [];
				const lastRun = activitiesRunsGroup[0];

				for (let i = 0; i <= activitiesRunsGroup.length - 1; i++) {
					const { job_id, status } = activitiesRunsGroup[i];
					if (status === Statuses.Success) nb_success++;
					if (status === Statuses.Failed) nb_failed++;
					if (!jobIds.includes(job_id)) jobIds.push(job_id);
				}

				return {
					id: lastRun.document_id,
					environment: lastRun.environment,
					configuration_type: lastRun.configuration_type,
					configuration_id: lastRun.configuration_id,
					job_id: lastRun.job_id,
					executions: activitiesRunsGroup.length,
					nb_job_id: jobIds.length,
					status: lastRun.status,
					execution_date_utc: lastRun.execution_date_utc,
					duration: this.getFormattedDuration(lastRun.duration),
					nb_success,
					nb_failed,
				};
			});
		};

		return {
			type: this.listingType,
			moduleName: 'tailer-activities-runs',
			headers,
			overriddenColumns,
			customDataFetching,
			customFiltersValues: [this.configurationType],
		};
	}
}
