import { Component, Mixins } from 'vue-property-decorator';
import ActivitiesOverviewMixin from '@/mixins/data-operations/collection/activities-overview-mixin';

export const NB_EXECUTION = { text: '# Executions', sortable: true, value: 'executions' };
export const NB_SUCCESS = { text: '# Success', sortable: true, value: 'nb_success' };
export const NB_FAILED = { text: '# Failed', sortable: true, value: 'nb_failed' };

@Component
export default class ActivitiesJobsConfsMixin extends Mixins(ActivitiesOverviewMixin) {
	isJobIdNull(jobId: string) {
		return Boolean(!jobId || jobId === 'NO_MATCH' || jobId === 'NOT_SET' || jobId === '*');
	}

	getNbSuccessClasses(nbSuccess: number) {
		return {
			'green--text': nbSuccess > 0,
		};
	}

	getNbFailedClasses(nbFailed: number) {
		return {
			'red--text': nbFailed > 0,
		};
	}
}
